import React from "react";
import dayjs from "dayjs";
import "dayjs/locale/ar";
import "dayjs/locale/en";

const DateTimeDisplay = ({ date, format, rtl }) => {
  // Set the locale based on the rtl prop
  const locale = rtl ? "ar" : "en";
  dayjs.locale(locale);

  // Format the date
  const formattedDate = dayjs(date).format(format);

  // Apply conditional styles for text direction
  const containerStyle = {
    direction: rtl ? "rtl" : "ltr",
    textAlign: rtl ? "right" : "left",
  };

  return <div style={containerStyle}>{formattedDate}</div>;
};

// Define default props and prop types
DateTimeDisplay.defaultProps = {
  format: "D MMMM YYYY, HH:mm",
  rtl: false,
};

export default DateTimeDisplay;
