export const getNewTemplateDefaultDesign = (storeName, store_logo_url) => {
  return {
    counters: {
      u_row: 3,
      u_column: 3,
      u_content_text: 2,
      u_content_image: 3,
      u_content_timer: 1,
      u_content_button: 1,
      u_content_divider: 2,
      u_content_heading: 1,
    },
    body: {
      id: "HZO6u9t8Pb",
      rows: [
        {
          id: "OcCGfgt3tU",
          cells: [1],
          columns: [
            {
              id: "7MNOpEfkai",
              contents: [
                {
                  id: "mGQhC6FZo9",
                  type: "image",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    src: {
                      url: store_logo_url,
                      id: 21275608,
                      preview: {
                        url: "https://margrow.s3.us-east-1.amazonaws.com/1714941039658-logo.png",
                        width: 600,
                        height: 150,
                      },
                      maxWidth: "30%",
                      autoWidth: false,
                    },
                    textAlign: "center",
                    altText: "",
                    action: {
                      name: "web",
                      values: {
                        href: "{{store_url}}",
                        target: "_blank",
                      },
                      attrs: {
                        href: "{{href}}",
                        target: "{{target}}",
                      },
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_image_1",
                      description: "",
                      htmlClassNames: "u_content_image",
                    },
                    selectable: false,
                    draggable: false,
                    duplicatable: false,
                    deletable: false,
                    hideable: false,
                    pending: false,
                  },
                },
                {
                  id: "2Ktr5WlIxa",
                  type: "divider",
                  values: {
                    width: "100%",
                    border: {
                      borderTopWidth: "1px",
                      borderTopStyle: "solid",
                      borderTopColor: "#BBBBBB",
                    },
                    textAlign: "center",
                    containerPadding: "10px",
                    anchor: "",
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_divider_2",
                      description: "",
                      htmlClassNames: "u_content_divider",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                  },
                },
              ],
              values: {
                _meta: {
                  htmlID: "u_column_1",
                  htmlClassNames: "u_column",
                },
                border: {},
                padding: "0px",
                backgroundColor: "",
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            _styleGuide: null,
            backgroundColor: "",
            columnsBackgroundColor: "",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: "no-repeat",
              size: "custom",
              position: "center",
            },
            padding: "0px",
            anchor: "",
            hideDesktop: false,
            _meta: {
              htmlID: "u_row_1",
              description: "",
              htmlClassNames: "u_row",
            },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
        {
          id: "oDRn6vcoeX",
          cells: [1],
          columns: [
            {
              id: "EgJDufVoIt",
              contents: [
                {
                  id: "dlg5-ss59Z",
                  type: "heading",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    headingType: "h1",
                    fontSize: "22px",
                    textAlign: "right",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_heading_1",
                      description: "",
                      htmlClassNames: "u_content_heading",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: `<span><span><strong>عروض وتخفيضات مُذهلة في انتظارك في ${storeName}</strong></span></span>`,
                  },
                },
                {
                  id: "OUWaLhFy56",
                  type: "image",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    src: {
                      url: "https://cdn.tools.unlayer.com/image/placeholder.png",
                      width: 1600,
                      height: 400,
                    },
                    textAlign: "center",
                    altText: "",
                    action: {
                      name: "web",
                      values: {
                        href: "",
                        target: "_blank",
                      },
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_image_3",
                      description: "",
                      htmlClassNames: "u_content_image",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                  },
                },
                {
                  id: "DB12KiwD5T",
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    fontSize: "14px",
                    textAlign: "right",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_text_1",
                      description: "",
                      htmlClassNames: "u_content_text",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<p style="line-height: 140%;" data-sourcepos="13:1-13:21">نُسعدك بِخبرٍ مُفرح!<br /><br /></p>\n<p style="line-height: 140%;" data-sourcepos="15:1-15:53"><strong>قد حان وقت عروضنا المُذهلة!</strong></p>\n<p style="line-height: 140%;" data-sourcepos="17:1-17:93">نُقدم لك في هذه العروض فرصةً لا تُفوّت لشراء منتجاتك المُفضلة بأسعارٍ مُخفضة تُناسب ميزانيتك.</p>\n<p style="line-height: 140%;" data-sourcepos="19:1-19:61">استمتع بتخفيضات هائلة على تشكيلة واسعة من المنتجات</p>',
                  },
                },
                {
                  id: "RblW54gZkn",
                  type: "button",
                  values: {
                    href: {
                      name: "web",
                      values: {
                        href: "",
                        target: "_blank",
                      },
                    },
                    buttonColors: {
                      color: "#FFFFFF",
                      backgroundColor: "#3AAEE0",
                      hoverColor: "#FFFFFF",
                      hoverBackgroundColor: "#3AAEE0",
                    },
                    size: {
                      autoWidth: true,
                      width: "100%",
                    },
                    fontSize: "20px",
                    lineHeight: "120%",
                    textAlign: "center",
                    padding: "10px 20px",
                    border: {},
                    borderRadius: "4px",
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    containerPadding: "10px",
                    anchor: "",
                    _meta: {
                      htmlID: "u_content_button_1",
                      description: "",
                      htmlClassNames: "u_content_button",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                    text: '<span style="line-height: 24px;">تسوق الآن</span>',
                    calculatedWidth: 107,
                    calculatedHeight: 44,
                  },
                },
                {
                  id: "k4cH8kcKgF",
                  type: "divider",
                  values: {
                    width: "100%",
                    border: {
                      borderTopWidth: "1px",
                      borderTopStyle: "solid",
                      borderTopColor: "#BBBBBB",
                    },
                    textAlign: "center",
                    containerPadding: "10px",
                    anchor: "",
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_divider_1",
                      description: "",
                      htmlClassNames: "u_content_divider",
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                    hideable: true,
                  },
                },
              ],
              values: {
                _meta: {
                  htmlID: "u_column_2",
                  htmlClassNames: "u_column",
                },
                border: {},
                padding: "0px",
                borderRadius: "0px",
                backgroundColor: "",
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            _styleGuide: null,
            backgroundColor: "",
            columnsBackgroundColor: "",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: "no-repeat",
              size: "custom",
              position: "center",
            },
            padding: "0px",
            anchor: "",
            hideDesktop: false,
            _meta: {
              htmlID: "u_row_2",
              description: "",
              htmlClassNames: "u_row",
            },
            selectable: true,
            draggable: true,
            duplicatable: true,
            deletable: true,
            hideable: true,
          },
        },
        {
          id: "8PFnjJzqZA",
          cells: [1],
          columns: [
            {
              id: "dHf9Prxc9Q",
              contents: [
                {
                  id: "PuXGZi-TYy",
                  type: "text",
                  values: {
                    containerPadding: "10px",
                    anchor: "",
                    fontSize: "14px",
                    textAlign: "right",
                    lineHeight: "140%",
                    linkStyle: {
                      inherit: true,
                      linkColor: "#0000ee",
                      linkHoverColor: "#0000ee",
                      linkUnderline: true,
                      linkHoverUnderline: true,
                    },
                    hideDesktop: false,
                    displayCondition: null,
                    _styleGuide: null,
                    _meta: {
                      htmlID: "u_content_text_2",
                      description: "",
                      htmlClassNames: "u_content_text",
                    },
                    selectable: false,
                    draggable: false,
                    duplicatable: false,
                    deletable: false,
                    hideable: false,
                    text: `<p style="line-height: 140%;" data-sourcepos="13:1-13:104">إذا كان لديك أي أسئلة، فلا تتردد في التواصل معنا أو الاتصال بنا مع أطيب التحيات.</p>\n<p style="line-height: 140%;" data-sourcepos="13:1-13:104"><a href="{{unsubscribe_url}}" target="_self" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Int7dW5zdWJzY3JpYmVfdXJsfX0iLCJ0YXJnZXQiOiJfc2VsZiJ9fQ=="><strong>إلغاء الإشتراك</strong></a></p>\n<p style="line-height: 140%;" data-sourcepos="13:1-13:104"><br /><strong>فريق <span style="line-height: 19.6px;">${storeName}</span></strong></p>\n<p style="line-height: 140%;" data-sourcepos="13:1-13:104"><strong>جميع الحقوق محفوظة</strong></p>`,
                  },
                },
              ],
              values: {
                _meta: {
                  htmlID: "u_column_3",
                  htmlClassNames: "u_column",
                },
                border: {},
                padding: "0px",
                borderRadius: "0px",
                backgroundColor: "#d1d5d7",
              },
            },
          ],
          values: {
            displayCondition: null,
            columns: false,
            _styleGuide: null,
            backgroundColor: "",
            columnsBackgroundColor: "",
            backgroundImage: {
              url: "",
              fullWidth: true,
              repeat: "no-repeat",
              size: "custom",
              position: "center",
            },
            padding: "0px",
            anchor: "",
            hideDesktop: false,
            _meta: {
              htmlID: "u_row_3",
              description: "",
              htmlClassNames: "u_row",
            },
            selectable: false,
            draggable: false,
            duplicatable: false,
            deletable: false,
            hideable: false,
          },
        },
      ],
      headers: [],
      footers: [],
      values: {
        _styleGuide: null,
        popupPosition: "center",
        popupWidth: "600px",
        popupHeight: "auto",
        borderRadius: "10px",
        contentAlign: "center",
        contentVerticalAlign: "center",
        contentWidth: "500px",
        fontFamily: {
          label: "Arial",
          value: "arial,helvetica,sans-serif",
        },
        textColor: "#000000",
        popupBackgroundColor: "#FFFFFF",
        popupBackgroundImage: {
          url: "",
          fullWidth: true,
          repeat: "no-repeat",
          size: "cover",
          position: "center",
        },
        popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
        popupCloseButton_position: "top-right",
        popupCloseButton_backgroundColor: "#DDDDDD",
        popupCloseButton_iconColor: "#000000",
        popupCloseButton_borderRadius: "0px",
        popupCloseButton_margin: "0px",
        popupCloseButton_action: {
          name: "close_popup",
          attrs: {
            onClick:
              "document.querySelector('.u-popup-container').style.display = 'none';",
          },
        },
        language: {},
        backgroundColor: "#F9F9F9",
        preheaderText: "",
        linkStyle: {
          body: true,
          linkColor: "#0000ee",
          linkHoverColor: "#0000ee",
          linkUnderline: true,
          linkHoverUnderline: true,
        },
        backgroundImage: {
          url: "",
          fullWidth: true,
          repeat: "no-repeat",
          size: "custom",
          position: "center",
        },
        _meta: {
          htmlID: "u_body",
          htmlClassNames: "u_body",
        },
      },
    },
    schemaVersion: 18,
  };
};