import {
  Form,
  Typography,
  Select,
  Input,
  DatePicker,
  Popconfirm,
  Row,
  Col,
  Upload,
  Switch,
  Tag,
} from "antd";
import * as XLSX from "xlsx";
import React, { useState } from "react";
import COLORS from "../../../Style/colors";
import { useIntl } from "react-intl";
import CustomButton from "../../../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import openNotification from "../../../Components/Notifications";
import { createCampaign } from "../../../API/fetch";
import dayjs from "dayjs";
import EmojiInput from "../../../Components/EmojiInput/EmojiInput";
import { AiOutlineDownload } from "react-icons/ai";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import { message } from "antd";
import CustomModal from "../../../Components/CustomModal/CustomModal";

const { Option } = Select;
const { Title, Text } = Typography;
const { Dragger } = Upload;

function NewCampaign() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [excelFile, setExcelFile] = useState(null);
  const [fileError, setFileError] = useState(false);
  const [customerUploads, setCustomerUploads] = useState([]);
  const [limitWarning, setLimitWarning] = useState(false);
  const [remainingEmails, setRemainingEmails] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { templates, groups, store, campaigns, customers } = useSelector(
    (state) => state.store
  );
  const onFinish = async (values, hasLimit = false) => {
    try {
      if (
        user?.stores?.[0]?.domain?.includes("salla.sa") ||
        user?.stores?.[0]?.domain?.includes("lamsah.co") ||
        user?.stores?.[0]?.domain?.includes("soognet.sa")
      ) {
        openNotification({
          title: "هذه الخدمة غير مترفرة لديك",
          description: "قم بتسجيل اسم الدومين الخاص بك لتتمكن من إرسال الحملات",
          type: "error",
          rtl: true,
        });
        return;
      }
      setFileError(false);
      if (store?.subscriptions?.[0]?.plan === "free") {
        openNotification({
          title: "هذه الخدمة غير مترفرة لديك",
          description: "قم بترقية اشتركك لتتمكن من إرسال الحملات",
          type: "error",
          rtl: true,
        });
        return;
      }

      if (values.targets.includes("customEXCEL") && !excelFile) {
        setFileError(true);
        return;
      }
      // const limit = store?.monthlyCampaignLimit;
      // count all campaigns in the current month
      const currentMonth = dayjs().month();
      const currentYear = dayjs().year();
      let counter = 0;
      campaigns.forEach((c) => {
        if (
          dayjs(c.createdAt).month() === currentMonth &&
          dayjs(c.createdAt).year() === currentYear
        ) {
          counter++;
        }
      });
      // if (counter >= limit) {
      //   openNotification({
      //     title: "Error",
      //     description: `You have reached the limit of ${limit} campaigns for this month`,
      //     type: "error",
      //     rtl: true,
      //   });
      //   return;
      // }
      setLoading(true);
      const date = values.campaignTime.toDate();
      const data = {
        name: values.subject,
        date,
        targets: values.targets,
        template: values.template,
        subject: values.subject,
        customersEXCEL: customerUploads,
        saveNewCustomer: values.saveNewCustomer
          ? values.saveNewCustomer
          : false,
        hasLimit,
      };
      const res = await createCampaign(data);
      setLoading(false);
      openNotification({
        title: "Success",
        description: intl.formatMessage({ id: "createCampaignSuccess" }),
        type: "success",
        rtl: true,
      });
      navigate("/custom-campaigns");
    } catch (error) {
      console.log("error", error);
      if (error.response.data.status === "domainNotVerified") {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "ErrorCustomCampaign" }),
          description: intl.formatMessage({ id: "domainNotVerified" }),
          type: "error",
          rtl: true,
        });
        return;
      }
      if (error.response.data.status === "exceedLimit") {
        const { remaining } = error.response.data;
        setRemainingEmails(remaining);
        setLoading(false);
        setLimitWarning(true);
        return;
      }
      if (error.response.data.status === "notEligible") {
        setLoading(false);
        openNotification({
          title: intl.formatMessage({ id: "ErrorCustomCampaign" }),
          description: intl.formatMessage({
            id: "campaignTimeWarningDescription",
          }),
          type: "error",
          rtl: true,
        });
        return;
      }

      setLoading(false);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const downloadTemplate = () => {
    const headers = [["name", "email"]];
    const worksheet = XLSX.utils.aoa_to_sheet(headers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "CompaignTemplate.xlsx");
  };

  const handleUpload = (file) => {
    const fileName = file.name.split(".")[1];
    if (fileName !== "xls" && fileName !== "xlsx") {
      message.error(intl.formatMessage({ id: "excelFileAlert" }));
      return false;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const customers = XLSX.utils.sheet_to_json(sheet);
        setCustomerUploads(customers.filter((el) => el.email && el.name));
        setExcelFile([file]);
      };
      reader.readAsBinaryString(file);
    }
    return;
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => handleUpload(file),
    showUploadList: {
      extra: () => (
        <span style={{ color: "#cccccc", margin: "0 5px" }}>
          (
          {customerUploads.length > 0
            ? ` ${intl.formatMessage({ id: "validCustomers" })} ${
                customerUploads.length
              } `
            : intl.formatMessage({ id: "excelNoCustomer" })}
          )
        </span>
      ),
      showRemoveIcon: true,
      removeIcon: (
        <DeleteOutlined
          style={{ color: COLORS.danger }}
          onClick={(e) => {
            setExcelFile(null);
            setCustomerUploads([]);
          }}
        />
      ),
    },
  };

  const disableDates = (current) => {
    const { subscriptions } = store;
    const now = dayjs(); // Current time
    const threeDaysLater = dayjs().add(3, "days"); // 3 days after now
    const specificDate = dayjs(subscriptions[0].endDate, "YYYY-MM-DD");

    // Disable dates before today, after 3 days from today, and after the specific date
    return (
      current &&
      (current.isBefore(now, "day") ||
        current.isAfter(specificDate, "day") ||
        current.isAfter(threeDaysLater, "day"))
    );
  };

  const disableTimes = (current) => {
    const now = dayjs();
    if (current && current.isSame(now, "day")) {
      // Disable time for the current day
      return {
        disabledHours: () =>
          Array.from({ length: 24 }, (_, i) =>
            i < now.hour() ? i : -1
          ).filter((hour) => hour !== -1),
        disabledMinutes: (hour) =>
          hour === now.hour()
            ? Array.from({ length: 60 }, (_, i) =>
                i < now.minute() ? i : -1
              ).filter((minute) => minute !== -1)
            : [],
        disabledSeconds: () => [],
      };
    }
    return {};
  };

  return (
    <div className="tables-card-container" style={{ position: "relative" }}>
      <CustomModal
        title={intl.formatMessage({ id: "ErrorCustomCampaign" })}
        body={
          <Text>
            {intl.formatMessage({ id: "monthlyLimitWarning" })}{" "}
            <Tag color="green">{remainingEmails} </Tag>
            {intl.formatMessage({ id: "clients" })} ,{" "}
            {intl.formatMessage({ id: "campaignConfirmMessage" })}
          </Text>
        }
        handleConfirm={() => onFinish(form.getFieldsValue(), true)}
        confirmBtnTitle="resend"
        open={limitWarning}
        setOpen={setLimitWarning}
        showButton={false}
        type="warning"
      />

      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "newCampaign" })}</Title>
      </div>
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 5,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 15,
        }}
        className="light-shadow"
      >
        {/* Create a form that has name, type, emails for the campaign */}
        <Form
          form={form}
          layout="vertical"
          name="new-campaign"
          onFinish={onFinish}
          initialValues={{
            type: "email",
          }}
        >
          {() => {
            return (
              <>
                <Form.Item
                  label={intl.formatMessage({ id: "campaignName" })}
                  name="name"
                  style={{ display: "none" }}
                >
                  <Input />
                </Form.Item>
                <EmojiInput
                  form={form}
                  name="subject"
                  label={intl.formatMessage({ id: "subjectEmail" })}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                  setInputValue={setInputValue}
                  inputValue={inputValue}
                  showCounter={true}
                />
                <Form.Item
                  label={intl.formatMessage({ id: "campaignTime" })}
                  name="campaignTime"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm"
                    disabledDate={disableDates}
                    disabledTime={disableTimes}
                  />
                </Form.Item>
                {/* target */}
                <Form.Item
                  label={intl.formatMessage({ id: "target" })}
                  name="targets"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  <Select className="my-font" mode="multiple">
                    <Option value="SALLA" className="my-font">
                      {intl.formatMessage({ id: "sallaClients" })}
                    </Option>
                    {groups.map((group) => {
                      return (
                        <Option
                          value={group.groupId}
                          key={group.groupId}
                          className="my-font"
                        >
                          {group.name}
                        </Option>
                      );
                    })}
                    <Option value="customEXCEL" className="my-font" disabled>
                      {intl.formatMessage({ id: "customExcel" })}
                    </Option>
                    {/* <Option value="BOTH" disabled className="my-font">
                      {intl.formatMessage({ id: "both" })} 
                    </Option> */}
                  </Select>
                </Form.Item>
                {form.getFieldValue("targets")?.includes("customEXCEL") ? (
                  <Row
                    gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
                    style={{ marginBottom: "10px" }}
                  >
                    <Col xs={24}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                          gap: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <p>{intl.formatMessage({ id: "customExcel" })}</p>
                        <CustomButton
                          text={intl.formatMessage({ id: "downloadTemplate" })}
                          onClick={downloadTemplate}
                          loading={false}
                          type="default"
                          icon={<AiOutlineDownload />}
                        />
                      </div>
                      <div>
                        <Dragger
                          {...props}
                          fileList={excelFile}
                          style={{
                            border: fileError
                              ? `1px dashed ${COLORS.danger}`
                              : "",
                          }}
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            {intl.formatMessage({ id: "dragAndDropFile" })}
                          </p>
                          <p className="ant-upload-hint">
                            {intl.formatMessage({
                              id: "dragAndDropFileDescription",
                            })}
                          </p>
                        </Dragger>
                      </div>
                      {fileError ? (
                        <p style={{ color: COLORS.danger }}>
                          {intl.formatMessage({ id: "fileRequired" })}
                        </p>
                      ) : null}
                    </Col>
                  </Row>
                ) : null}
                {/* Template */} 
                {excelFile ? (
                  <Form.Item
                    name={"saveNewCustomer"}
                    label={intl.formatMessage({ id: "saveFileCustomer" })}
                    style={{ display: "flex" }}
                  >
                    <Switch
                      checkedChildren={
                        <p>
                          {intl.formatMessage({
                            id: "yes",
                          })}
                        </p>
                      }
                      unCheckedChildren={
                        <p>
                          {intl.formatMessage({
                            id: "no",
                          })}
                        </p>
                      }
                    />
                  </Form.Item>
                ) : null}
                <Form.Item
                  label={intl.formatMessage({ id: "template" })}
                  name="template"
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({ id: "required" }),
                    },
                  ]}
                >
                  {templates.length === 0 ? (
                    <CustomButton
                      text={intl.formatMessage({ id: "createTemplate" })}
                      type="primary"
                      onClick={() => {
                        navigate("/new-template");
                      }}
                      style={{
                        marginBottom: 10,
                      }}
                    />
                  ) : null}
                  <Select
                    className="my-font"
                    disabled={templates?.length === 0}
                    placeholder={
                      templates?.length === 0
                        ? intl.formatMessage({ id: "noTemplate" })
                        : ""
                    }
                  >
                    {templates.map((template) => {
                      return (
                        <Option
                          value={template._id}
                          key={template._id}
                          className="my-font"
                        >
                          {template.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  {/* {store?.remainingEmails < customers?.length ? (
                    <Popconfirm
                      title={`رصيدك المتبقي لا يكفي، هل تريد المتابعة ؟`}
                      description={`رصيدك الحالي ${store?.remainingEmails} وعدد العملاء ${customers?.length} سيتم الإرسال لعملاء عشوائيين بعدد الايميلات المتبقية`}
                      onConfirm={() => {
                        form.submit();
                      }}
                      onCancel={() => true}
                      okText="إرسال"
                      cancelText="تراجع"
                    >
                      <CustomButton
                        text={intl.formatMessage({ id: "send" })}
                        type="primary"
                        loading={loading}
                      />
                    </Popconfirm>
                  ) : ( */}
                  <CustomButton
                    text={intl.formatMessage({ id: "send" })}
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    // disabled={store?.subscriptions?.[0]?.plan === "free"}
                  />
                  {/* )} */}
                </Form.Item>
              </>
            );
          }}
        </Form>
      </div>
    </div>
  );
}

export default NewCampaign;
