import { Form, Typography, Select, Input, DatePicker, Row, Col } from "antd";
import React, { useEffect, useRef, useState } from "react";
import COLORS from "../../Style/colors";
import { useIntl } from "react-intl";
import CustomButton from "../../Components/CustomButton";
import { useNavigate } from "react-router-dom";
import EmailEditor from "react-email-editor";
import openNotification from "../../Components/Notifications";
import { createAdminTemplate } from "../../API/fetch";
import { useSelector } from "react-redux";
import TagModal from "./TagModal";

const { Option } = Select;
const { Title, Text } = Typography;
function NewTemplateAdmin() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const emailEditorRef = useRef(null);
  const [templateValue, setTemplateValue] = useState([]);
  const [showTagModal, setShowTagModal] = useState(false);

  const onFinish = async (values) => {
    emailEditorRef.current.editor.exportHtml(async (data) => {
      try {
        setLoading(true);
        let formatted = [];
        if (Object.keys(templateValue).length > 0) {
          for (const [key, value] of Object.entries(templateValue)) {
            formatted.push({
              name: key,
              value: value.value,
            });
          }
        }

        const { design, html } = data;
        const myData = {
          name: values.name,
          html,
          design,
          variables: formatted,
          status: values.status,
        };

        const res = await createAdminTemplate(myData);
        setLoading(false);
        openNotification({
          title: "Success",
          description: "Template created successfully",
          type: "success",
          rtl: true,
        });
        navigate("/templates-admin");
      } catch (error) {
        console.log("error", error);
        setLoading(false);
        openNotification({
          title: "Error",
          description: "something went wrong",
          type: "error",
          rtl: true,
        });
      }
    });
  };

  const onLoad = () => {};

  const onReady = () => {
    // editor is ready
    console.log("onReady");
  };
  return (
    <div className='tables-card-container' style={{ position: "relative" }}>
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "template" })}</Title>
      </div>
      <TagModal
        show={showTagModal}
        setShow={setShowTagModal}
        templateValue={templateValue}
        setTemplateValue={setTemplateValue}
        updateDom={() => {
          emailEditorRef.current.editor.setMergeTags({
            ...templateValue,
          });
        }}
      />
      <div
        style={{
          backgroundColor: COLORS.white,
          marginTop: 5,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          borderRadius: 10,
          padding: 15,
        }}
        className='light-shadow'
      >
        {/* Create a form that has name, type, emails for the campaign */}
        <Row
          gutter={{ xs: 16, sm: 24, md: 32, lg: 40 }}
          // justify={"space-between"}
          style={
            {
              // backgroundColor: "green",
            }
          }
        >
          <Col md={24} sm={24} xs={24}>
            <Form
              form={form}
              layout='vertical'
              name='new-campaign'
              onFinish={onFinish}
              initialValues={{
                type: "email",
                status: "pending",
              }}
            >
              <Row gutter={16}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "templateName" })}
                    name='name'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={6} sm={24} xs={24}>
                  <Form.Item label={intl.formatMessage({ id: "language" })}>
                    <Select
                      className='my-font'
                      onChange={(e) => {
                        emailEditorRef.current.editor.setLocale(e);
                      }}
                    >
                      <Option value='en-US' className='my-font'>
                        {intl.formatMessage({ id: "english" })}
                      </Option>
                      <Option value='ar-AE' className='my-font'>
                        {intl.formatMessage({ id: "arabic" })}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6} sm={24} xs={24}>
                  <CustomButton
                    type='primary'
                    text={intl.formatMessage({ id: "addTags" })}
                    style={{
                      width: "100%",
                      marginTop: 20,
                    }}
                    onClick={() => {
                      setShowTagModal(true);
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12} sm={24} xs={24}>
                  <Form.Item
                    label={intl.formatMessage({ id: "status" })}
                    name='status'
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: "required" }),
                      },
                    ]}
                  >
                    <Select
                      className='my-font'
                      style={{
                        width: "100%",
                      }}
                    >
                      <Option value='pending' className='my-font'>
                        {intl.formatMessage({ id: "pending" })}
                      </Option>
                      <Option value='active' className='my-font'>
                        {intl.formatMessage({ id: "active" })}
                      </Option>
                      <Option value='deleted' className='my-font'>
                        {intl.formatMessage({ id: "deleted" })}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Col
                md={24}
                sm={24}
                xs={24}
                style={{
                  border: "1px solid #f0f0f0",
                  overflow: "hidden",
                }}
              >
                <EmailEditor
                  ref={emailEditorRef}
                  onLoad={onLoad}
                  onReady={onReady}
                  options={{
                    locale: "ar-AE",
                    projectId: 223654,
                    designMode :"edit"
                  }}
                />
              </Col>
              <Row gutter={16}>
                <Col md={24} sm={24} xs={24}>
                  <Form.Item>
                    <CustomButton
                      type='primary'
                      htmlType='submit'
                      loading={loading}
                      text={intl.formatMessage({ id: "create" })}
                      style={{
                        width: "100%",
                        marginTop: 20,
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
          {/* <Col
            md={1}
            sm={1}
            xs={1}
            style={{
              backgroundColor: COLORS.gray,
              position: "relative",
              top: -20,
            }}
          ></Col> */}
        </Row>
      </div>
    </div>
  );
}

export default NewTemplateAdmin;
