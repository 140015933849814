import React from "react";
import COLORS from "../Style/colors";
import { Typography } from "antd";
import { MailOutlined, SendOutlined } from "@ant-design/icons";
import { IoMailOpenOutline } from "react-icons/io5";
import { BsCursor } from "react-icons/bs";
const { Title, Text } = Typography;

function CompaignStatsCard({ value, title, subtitle, type }) {
  return (
    <div
      style={{
        backgroundColor: COLORS.white,
        borderRadius: 10,
        padding: 15,
        maxWidth: 250,
        width: "100%",
        height: 160,
      }}
      className="light-shadow"
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <Title level={5} style={{ margin: 0 }}>
          {title}
        </Title>
        <div
          style={{
            width: 28,
            height: 28,
            background:
              type === "sent"
                ? "#ffe7ba"
                : type === "delivred"
                ? "#d9f7be"
                : type === "open"
                ? "#b5f5ec"
                : "#d6e4ff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "100%",
          }}
        >
          {type === "sent" ? (
            <MailOutlined style={{ color: "#d46b08" }} />
          ) : type === "delivred" ? (
            <SendOutlined style={{ color: "#389e0d", rotate: "215deg" }} />
          ) : type === "open" ? (
            <IoMailOpenOutline style={{ color: "#08979c" }} />
          ) : (
            <BsCursor style={{ color: "#1d39c4" }} />
          )}
        </div>
      </div>
      <div>
        <Title level={2}>{value}</Title>
      </div>
      <Text level={5} style={{ color: "#808080" }}>
        {subtitle}
      </Text>
    </div>
  );
}

export default CompaignStatsCard;
