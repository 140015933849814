import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/CustomButton";
import { useIntl } from "react-intl";
import { cancelCampaign, getStoreCampaigns } from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import { Popconfirm, Spin, Table, Tag, Typography } from "antd";
import COLORS from "../../Style/colors";
import openNotification from "../../Components/Notifications";
import { setStoreCampaignsAction } from "../../redux/reducers/storeReducer";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DateTimeDisplay from "../../Components/FormatData";
import { AiOutlineLine } from "react-icons/ai";

const { Title } = Typography;

function CustomCampaign() {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { user } = useSelector((state) => state.auth);
  const { store } = useSelector((state) => state.store);
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState([]);
  const [canAddCampign, setCanAddCampign] = useState(true);
  useEffect(() => {
    if (store?._id) {
      getCampaigns();
    }
  }, [store?._id]);

  const getCampaigns = async () => {
    try {
      setLoading(true);
      const res = await getStoreCampaigns();
      if (res?.data?.campaigns) {
        dispatch(setStoreCampaignsAction(res?.data?.campaigns));
        setCampaignData(res?.data?.campaigns);
        setLoading(false);
        // const limit = store?.monthlyCampaignLimit;
        // count all campaigns in the current month
        const currentMonth = dayjs().month();
        const currentYear = dayjs().year();
        let counter = 0;
        res?.data?.campaigns.forEach((c) => {
          if (
            dayjs(c.createdAt).month() === currentMonth &&
            dayjs(c.createdAt).year() === currentYear
          ) {
            counter++;
          }
        });

        // openNotification({
        //   title: intl.formatMessage({ id: "success" }),
        //   type: "success",
        //   description: intl.formatMessage({ id: "successUpdate" }),
        //   rtl,
        // });
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl,
      });
    }
  };

  const submitCancel = async (id) => {
    try {
      setLoading(true);
      const res = await cancelCampaign(id);
      const { status } = res.data;
      if (status === "success") {
        openNotification({
          title: "Success",
          description: "Compaign cancelled successfully",
          type: "success",
          rtl: true,
        });
        getCampaigns();
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  const getCampaignStatus = (date, status) => {
    const actualTime = dayjs();
    if (status === "cancelled") {
      return "cancelled";
    } else if (
      (status === "active" && dayjs(date).isBefore(actualTime)) ||
      status === "done"
    ) {
      return "done";
    } else {
      return "scheduled";
    }
  };

  const columns = [
    {
      title: intl.formatMessage({ id: "subjectEmail" }),
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: intl.formatMessage({ id: "isSent" }),
      dataIndex: "sentCount",
      key: "sentCount",
      render: (text, row) => {
        const status = getCampaignStatus(row.date, row.status);
        return (
          <div>
            {status === "cancelled" ? <AiOutlineLine /> : <Tag>{text}</Tag>}
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "isDelivered" }),
      dataIndex: "deliveredCount",
      key: "deliveredCount",
      render: (text, row) => {
        const status = getCampaignStatus(row.date, row.status);
        return (
          <div>
            {status === "cancelled" ? <AiOutlineLine /> : <Tag>{text}</Tag>}
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "openedCount" }),
      dataIndex: "openedCount",
      key: "openedCount",
      render: (text, row) => {
        const status = getCampaignStatus(row.date, row.status);
        return (
          <div>
            {status === "cancelled" ? <AiOutlineLine /> : <Tag>{text}</Tag>}
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "clickedCount" }),
      dataIndex: "clickedCount",
      key: "clickedCount",
      render: (text, row) => {
        const status = getCampaignStatus(row.date, row.status);
        return (
          <div>
            {status === "cancelled" ? <AiOutlineLine /> : <Tag>{text}</Tag>}
          </div>
        );
      },
    },
    // {
    //   title: intl.formatMessage({ id: "failed" }),
    //   dataIndex: "failed",
    //   key: "failed",
    // },
    {
      title: intl.formatMessage({ id: "status" }),
      dataIndex: "status",
      key: "status",
      render: (text, row) => {
        const status = getCampaignStatus(row.date, text);
        return (
          <div>
            {status === "cancelled" ? (
              <Tag color="red">{intl.formatMessage({ id: status })}</Tag>
            ) : status === "done" ? (
              <Tag color="green">{intl.formatMessage({ id: status })}</Tag>
            ) : (
              <Tag color="yellow">{intl.formatMessage({ id: status })}</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "date" }),
      dataIndex: "date",
      key: "date",
      render: (text) => <DateTimeDisplay date={new Date(text)} rtl={true} />,
      // <Text>{new Date(text).toDateString()}</Text>
    },
    {
      key: "action",
      render: (text, record) => {
        const status = getCampaignStatus(record.date, record.status);
        return status === "cancelled" ? null : status === "done" ? (
          <CustomButton
            text={intl.formatMessage({ id: "viewStats" })}
            onClick={() => {
              navigate(`/campaigns/stats/${record._id}`);
            }}
            type="link"
          />
        ) : (
          <Popconfirm
            title={intl.formatMessage({ id: "cancelCampaign" })}
            description={intl.formatMessage({
              id: "cancelCampaignDescription",
            })}
            onConfirm={() => {
              submitCancel(record._id);
            }}
            onCancel={() => true}
            okText={intl.formatMessage({ id: "confirm" })}
            cancelText={intl.formatMessage({ id: "cancel" })}
          >
            <CustomButton
              text={intl.formatMessage({ id: "cancel" })}
              type="link"
              style={{ color: COLORS.danger }}
            />
          </Popconfirm>
        );
      },
    },
  ];

  useEffect(() => {
    if (
      user?.stores?.[0]?.domain?.includes("salla.sa") ||
      user?.stores?.[0]?.domain?.includes("soognet.sa")
    ) {
      setCanAddCampign(false);
    } else {
      setCanAddCampign(true);
    }
  }, [user, store]);

  return (
    <div className="tables-card-container" style={{ position: "relative" }}>
      <div
        style={{
          // width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 30,
          marginLeft: 30,
        }}
      >
        <Title level={5}>{intl.formatMessage({ id: "customCampaigns" })}</Title>
        <CustomButton
          text={intl.formatMessage({ id: "addCampaign" })}
          onClick={() => {
            navigate("/new-campaign");
          }}
          type="primary"
          disabled={!canAddCampign}
        />
      </div>

      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 5,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className="light-shadow"
        >
          <Table dataSource={campaignData} columns={columns} />
        </div>
      </Spin>
    </div>
  );
}

export default CustomCampaign;
