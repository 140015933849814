import { Spin, Table, Tag, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import COLORS from "../../../Style/colors";
import { useParams } from "react-router-dom";
import { getStoreCampaignStats } from "../../../API/fetch";
import openNotification from "../../../Components/Notifications";
import CompaignStatsCard from "../../../Components/CompaignStatsCard";
import DateTimeDisplay from "../../../Components/FormatData";
import { AiOutlineLine } from "react-icons/ai";
const { Title } = Typography;

function StatsCompaign() {
  const intl = useIntl();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [emailsStats, setEmailsStats] = useState(null);

  const getCampaignData = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getStoreCampaignStats(id);
      if (res?.data?.campaign) {
        setCampaignData(res?.data?.campaign);
        setLoading(false);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl: true,
      });
    }
  }, [id, intl]);

  useEffect(() => {
    getCampaignData();
  }, [getCampaignData]);

  useEffect(() => {
    if (campaignData) {
      const emails = campaignData?.depthEmails?.map((el) => {
        return {
          ...el,
          status: getEmailsStatus({ status: el.status, record: el }),
        };
      });
      setEmailsStats(emails);
    }
  }, [campaignData]);

  console.log(campaignData);

  function calculatePercentage({ part, total }) {
    if (total === 0) {
      return 0;
    }
    const percentage = (part / total) * 100;
    if (percentage % 1 === 0) {
      return percentage;
    }
    return percentage.toFixed(2);
  }

  function getEmailsStatus({ status, record }) {
    if (
      (status === "sent" && record.clickedCount > 0) ||
      status === "clicked"
    ) {
      status = "clicked";
    } else if (
      (status === "sent" && record.openedCount > 0) ||
      status === "opened"
    ) {
      status = "opened";
    } else if (
      (status === "sent" && record.deliveredCount > 0) ||
      status === "delivered"
    ) {
      status = "delivered";
    } else {
      status = "sent";
    }
    return status;
  }

  const columns = [
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
      key: "email",
    },

    {
      title: intl.formatMessage({ id: "lastEvent" }),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.deliveredCount - b.deliveredCount,
      render: (text, record) => {
        return (
          <div>
            <Tag
              color={
                text === "sent"
                  ? "orange"
                  : text === "delivered"
                  ? "green"
                  : text === "opened"
                  ? "cyan"
                  : "geekblue"
              }
            >
              {intl.formatMessage({ id: text })}
            </Tag>
          </div>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "eventDate" }),
      dataIndex: "date",
      key: "date",
      sorter: (a, b) => a.openedCount - b.openedCount,
      render: (text) => {
        return text ? (
          <DateTimeDisplay date={new Date(text)} rtl={true} />
        ) : (
          <AiOutlineLine />
        );
      },
    },
  ];

  return (
    <div className="tables-card-container" style={{ position: "relative" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        <Title level={4}>{intl.formatMessage({ id: "campaignStats" })}</Title>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            marginTop: 5,
            width: "100%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: 15,
            display: "flex",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          <CompaignStatsCard
            title={intl.formatMessage({ id: "sent" })}
            subtitle={intl.formatMessage({ id: "emails" })}
            value={campaignData ? campaignData.sentCount : 0}
            type="sent"
          />
          <CompaignStatsCard
            title={intl.formatMessage({ id: "deliveryRate" })}
            subtitle={`${intl.formatMessage({ id: "isDelivered" })} : ${
              campaignData ? campaignData?.deliveredCount : 0
            }`}
            value={
              campaignData
                ? `${calculatePercentage({
                    total: campaignData?.sentCount,
                    part: campaignData?.deliveredCount,
                  })} %`
                : 0
            }
            type="delivred"
          />

          <CompaignStatsCard
            title={intl.formatMessage({ id: "openRate" })}
            subtitle={`${intl.formatMessage({ id: "openedCount" })} : ${
              campaignData ? campaignData?.openedCount : 0
            }`}
            value={
              campaignData
                ? `${calculatePercentage({
                    total: campaignData?.deliveredCount,
                    part: campaignData?.openedCount,
                  })} %`
                : 0
            }
            type="open"
          />

          <CompaignStatsCard
            title={intl.formatMessage({ id: "openClickRate" })}
            subtitle={`${intl.formatMessage({ id: "clickedCount" })} : ${
              campaignData ? campaignData?.clickedCount : 0
            }`}
            value={
              campaignData
                ? `${calculatePercentage({
                    total: campaignData?.openedCount,
                    part: campaignData?.clickedCount,
                  })} %`
                : 0
            }
            type="click"
          />
        </div>
      </Spin>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 25,
            width: "98%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className="light-shadow"
        >
          <Title level={5} style={{ marginBottom: 15 }}>
            {intl.formatMessage({ id: "emailsPerformance" })}
          </Title>
          <Table dataSource={emailsStats} columns={columns} />
        </div>
      </Spin>
    </div>
  );
}

export default StatsCompaign;
