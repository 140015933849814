import {
  Col,
  Image,
  Modal,
  Spin,
  Typography,
  message,
  Upload,
  Row,
} from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import apiUrl from "../API/config";
import COLORS from "../Style/colors";
import { useIntl } from "react-intl";
import { InboxOutlined } from "@ant-design/icons";
import { setMediasAction } from "../redux/reducers/storeReducer";
import openNotification from "./Notifications";
import CustomButton from "./CustomButton";
import { deleteMedia } from "../API/fetch";
import { isPaidStore } from "../helpers/isPaid";

const { Dragger } = Upload;
const acceptedTypes = ["image/png", "image/jpg", "image/jpeg"];
const { Text, Title } = Typography;
function ImageLibrary({ open, onClose }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state?.settings);
  const { products, store, medias } = useSelector((state) => state.store);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [logos, setLogos] = useState([]);
  const [errorSizeImage, setErrorSizeImage] = useState("");

  
  const deleteImage = async (media) => {
    try {
      setLoading(true);
      const res = await deleteMedia(media._id);
      if (res?.data?.status === "success") {
        const newMedias = medias.filter((one) => one._id !== media._id);
        dispatch(setMediasAction(newMedias));
        openNotification({
          title: intl.formatMessage({ id: "deletedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
      setLoading(false);
    }
  };

  const handleChangeImages = async ({ file, fileList }) => {
    let newFileList = [...fileList];
    setErrorSizeImage(false);
    let errors = [];
    newFileList.forEach((el) => {
      if (el.size > 2000000) {
        //check if the image size is bigger than 2Mo
        errors.push(el?.uid);
      }
    });
    if (errors.length > 0) {
      //return error if there is images bigger than 2Mo
      setErrorSizeImage(true);
      return;
    }
    if (file.status === "uploading") {
      setLoading(true); // show loading;
    } else if (file.status === "done") {
      //   // upload success;
      setLoading(false); // hide loading;
      if (file.response?.media) {
        const newMedias = [...medias];
        newMedias.push(file.response.media);
        dispatch(setMediasAction(newMedias));
        openNotification({
          title: intl.formatMessage({ id: "savedSuccessfully" }),
          type: "success",
          rtl,
        });
      }
    } else if (file.status === "error") {
      //   setLoading(false); // hide loading;
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        description: intl.formatMessage({ id: "errorfetch" }),
        type: "error",
        rtl,
      });
    }
    setLogos([file]);
  };

  const beforeUpload = (file) => {
    if (!acceptedTypes.includes(file?.type) || !file) {
      message.error("You can only upload JPG/PNG file!");
      return false;
    }
    if (file.type?.includes("image")) {
      if (file.size / 1000000 > 2.2) {
        message.error(intl.formatMessage({ id: "limit2Mb" }));
        return false;
      }
    }

    return true;
  };
  return (
    <Modal
      title='مكتبة الصور'
      open={open}
      onCancel={() => onClose()}
      footer={null}
      width={"98vw"}
    >
      <Row gutter={[16, 16]}>
        <Col md={6} sm={12} sx={24}>
          <Image
            src={store.merchantAvatar}
            alt={store.storeName}
            style={{ width: 200, cursor: "pointer" }}
            preview={false}
            onClick={() => {
              onClose(store.merchantAvatar);
            }}
          ></Image>
        </Col>
        {products.map((product, index) => {
          return (
            <Col md={6} sm={12} sx={24} key={`${product._id}-${index}`}>
              <Image
                key={product._id}
                src={product.mainImage}
                alt={product.name}
                style={{ width: 200, cursor: "pointer" }}
                preview={false}
                onClick={() => {
                  onClose(product.mainImage);
                }}
              ></Image>
            </Col>
          );
        })}
        {medias.map((media) => {
          return (
            <Col key={media._id} md={6} sm={12} sx={24}>
              <div
                key={media._id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Image
                  key={media._id}
                  src={media.mediaUrl}
                  alt={store?.storeName}
                  style={{ width: 200, cursor: "pointer" }}
                  preview={false}
                  onClick={() => {
                    onClose(media.mediaUrl);
                  }}
                ></Image>
                <CustomButton
                  type='danger'
                  text={intl.formatMessage({ id: "delete" })}
                  onClick={() => deleteImage(media)}
                  disabled={loading}
                  loading={loading}
                  style={{
                    backgroundColor: COLORS.danger,
                    color: COLORS.white,
                    marginTop: 10,
                  }}
                />
              </div>
            </Col>
          );
        })}
      </Row>
      <Spin spinning={loading}>
        <Title className='my-font' level={5}>
          {intl.formatMessage({ id: "addCustomPhoto" })}
        </Title>
        <div
          style={{
            position: "absolute",
            top: 15,
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {errorSizeImage ? (
            <Text className='my-font' style={{ color: COLORS.danger }}>
              {intl.formatMessage({ id: "limit2Mb" })}
            </Text>
          ) : null}
        </div>

        <Dragger
          disabled={loading || isPaidStore(store) === false}
          action={`${apiUrl}/media?storeId=${store._id}`}
          headers={{ authorization: "Bearer " + user.token }}
          name='picture'
          fileList={logos}
          listType='picture'
          onChange={handleChangeImages}
          beforeUpload={beforeUpload}
          multiple={false}
          maxCount={0}
          accept='image/jpg,image/jpeg,image/png,video/mp4'
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <InboxOutlined
              style={{
                color: errorSizeImage ? COLORS.danger : COLORS.primary,
                fontSize: "2rem",
              }}
              className='mx-3'
            />
            <Text
              style={{
                fontSize: "1rem",
                color:
                  errorSizeImage || isPaidStore(store) === false
                    ? COLORS.danger
                    : COLORS.primary,
              }}
              className='my-font'
            >
              {intl.formatMessage({
                id:
                  isPaidStore(store) === false
                    ? "unAvalibleInFreePlan"
                    : "addPhoto",
              })}
            </Text>
          </div>
        </Dragger>
      </Spin>
    </Modal>
  );
}

export default ImageLibrary;
