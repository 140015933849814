let apiUrl;
const expressPort = 3003;

const apiUrls = {
  production: "https://api.margrow.com/api/v1",
  development: `http://localhost:${expressPort}/api/v1`,
};

if (
  window.location.hostname === "localhost" ||
  window.location.hostname.includes("192") || 
  window.location.hostname.includes("ngrok-free")
) {
  apiUrl = apiUrls.development
} else {
  apiUrl = apiUrls.production;
}

export default apiUrl;
